// Import the functions from Redux Toolkit
import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit'

// Define the initial state of the counter feature
interface CounterState {
	value: number
}

const initialState: CounterState = {
	value: 0,
}

// Create a slice for the counter feature
const counterSlice = createSlice({
	name: 'counter',
	initialState,
	reducers: {
		// Define actions and reducers in one go
		incremented: (state) => {
			state.value += 1
		},
		decremented: (state) => {
			state.value -= 1
		},
	},
})

// Extract the action creators object and the reducer
const { actions, reducer } = counterSlice

// Export the actions
export const { incremented, decremented } = actions

// Configure the store with the reducer
const store = configureStore({
	reducer: {
		counter: reducer,
	},
})

export default store
