import { createTheme, MuiThemeProvider } from '@material-ui/core'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { FC, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import Layout from 'sections/shared/Layout'
import store from 'state/store'
import { themes } from 'styles/theme'
import { getDesignTokens } from 'utils/theme'
import 'styles/main.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '@reach/dialog/styles.css'
import '../i18n'
import Script from 'next/script' // Import the Script component

type NextPageWithLayout = NextPage & {
	getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout
}

const InnerApp: FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
	const [isReady, setReady] = useState(false)
	const getLayout = Component.getLayout || ((page) => page)
	const currentTheme = 'dark'
	const queryClient = new QueryClient()
	// @ts-ignore
	const theme = useMemo(() => themes[currentTheme], [currentTheme])
	// @ts-ignore palette options
	const muiTheme = useMemo(() => createTheme(getDesignTokens(currentTheme)), [currentTheme])

	useEffect(() => {
		setReady(true)
	}, [])

	return isReady ? (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={theme}>
				<MuiThemeProvider theme={muiTheme}>
					<Layout>{getLayout(<Component {...pageProps} />)}</Layout>
					<ReactQueryDevtools position="top-left" />
				</MuiThemeProvider>
			</ThemeProvider>
		</QueryClientProvider>
	) : null
}

const App: FC<AppProps> = (props) => {
	const { t } = useTranslation()
	return (
		<>
			<Head>
				<Script
					src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5288091101907546"
					strategy="afterInteractive"
					crossOrigin="anonymous"
					async
				></Script>
				<meta name="google-adsense-account" content="ca-pub-5288091101907546" />				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
				/>
				<meta name="title" content={t('meta.og.title')} />
				<meta name="description" content={t('meta.description')} />
				{/* open graph */}
				<meta property="og:title" content={t('meta.og.title')} />
				<meta property="og:description" content={t('meta.description')} />
				<meta property="og:url" content="https://www.todamoon.trading/" />
				<meta property="og:type" content="website" />
				<meta property="og:image" content="/images/todamoon.jpg" />
				<meta property="og:image:alt" content={t('meta.og.title')} />
				<meta property="og:site_name" content={t('meta.og.site-name')} />
				{/* twitter */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@ToDaMoon_trading" />
				<meta name="twitter:creator" content="@ToDaMoon_trading" />
				<meta name="twitter:image" content="https://i.ibb.co/TqTP9xt/ToDaMoon.png" />
				<meta name="twitter:url" content="https://www.todamoon.trading/" />
				<link rel="icon" href="/images/favicon.svg" />
			</Head>
			<Provider store={store}>
				<InnerApp {...props} />
			</Provider>
		</>
	)
}

export default App
